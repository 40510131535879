import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import requestmanager from '../../lib/requestmanager';
import Loading from './Loading';

const MainPage = lazy(() => import('./MainPage'));
const Profile = lazy(() => import('./Profile'));
const QRCodeReader = lazy(() => import('./QRCodeReader'));
const LoginPage = lazy(() => import('./LoginPage'));
const NotFound = lazy(() => import('./NotFound'));
const Reservation = lazy(() => import('./Reservation'));

export default class HelloWorld extends React.Component {
  constructor(props) {
    super(props);

    this.auth_token = localStorage.getItem('auth_token');

    this.state = {
      current_user: null,
      isCheckUser: !this.auth_token,
      qrcode_value: null
    };
    window.navigator.onLine;
  }

  UNSAFE_componentWillMount() {
    this.getUser();
  }

  componentDidMount() {
    window.addEventListener('online', () => this.getUser());
  }

  getUser = () => {
    if (this.auth_token) {
      const url = '/api/v1/users/' + this.auth_token;
      requestmanager.request(url).then((resp) => {
        if (resp !== undefined) {
          localStorage.setItem('language', resp.language);
          this.setState({ current_user: resp, isCheckUser: true, qrcode_value: resp.qrcode_value });
        } else {
          this.setState({ isCheckUser: true });
          localStorage.removeItem('auth_token');
        }
      }).catch(() => { });
    }
  }

  render() {
    const user = this.state.current_user || undefined;
    return (
      <div>
        {this.state.isCheckUser ?
          (<Router>
            {user ? (
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    exact path='/' render={_props =>
                    (<MainPage
                      auth_token={user.auth_token}
                      bonuses={user.bonuses}
                      business_bonuses={user.business_bonuses}
                      qrcode_value={this.state.qrcode_value}
                      user_id={user.user_id}
                      waiter={user.waiter} />)} />
                  <Route
                    component={Reservation}
                    exact path='/reserve' />
                  <Route
                    exact path='/profile' render={_props =>
                    (<Profile
                      current_user={user} />)} />
                  {user.waiter ?
                    (<Route
                      exact path='/qrscan' render={_props =>
                      (<QRCodeReader
                        business_id={user.business_id}
                        user_id={user.id}
                        business_bonuses={user.business_bonuses}
                        bonus_percent={user.bonus_percent}
                        waiter={user.waiter} />)} />) : undefined}
                  <Route component={NotFound} />
                </Switch>
              </Suspense>) :
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route
                    component={LoginPage} path='/' />
                </Switch>
              </Suspense>}
          </Router>) : <Loading />}
      </div>
    );
  }
}
